import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Our goal with Primer React is to hit the sweet spot between providing too little and too much styling flexibility; too little and the design system is too rigid, and too much and it becomes too difficult to maintain a consistent style. Our components are designed to cover common usage patterns, but sometimes a component just isn't `}<em parentName="p">{`quite`}</em>{` flexible enough to look the way you need it to look. For those cases, we provide the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop allows ad-hoc styling that is still theme-aware. Declare the styles you want to apply in camelCase object notation, and try to use theme values in appropriate CSS properties when possible. If you've passed a custom theme using `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` or a `}<inlineCode parentName="p">{`theme`}</inlineCode>{` prop, the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop will honor the custom theme. For more information on theming in Primer React, check out `}<a parentName="p" {...{
        "href": "/primer-theme"
      }}>{`the Primer Theme documentation`}</a>{`.`}</p>
    <h2>{`When to use the `}<inlineCode parentName="h2">{`sx`}</inlineCode>{` prop`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop provides a lot of power, which means it is an easy tool to abuse. To best make use of it, we recommend following these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`sx`}</inlineCode>{` prop for small stylistic changes to components. For more substantial changes, consider abstracting your style changes into your own wrapper component.`}</li>
      <li parentName="ul">{`Avoid nesting and pseudo-selectors in `}<inlineCode parentName="li">{`sx`}</inlineCode>{` prop values when possible.`}</li>
    </ul>
    <h2>{`Basic example`}</h2>
    <p>{`This example demonstrates applying a bottom border to `}<inlineCode parentName="p">{`Heading`}</inlineCode>{`, a component that does not receive `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{` system props. The `}<inlineCode parentName="p">{`borderBottomWidth`}</inlineCode>{` value comes from `}<inlineCode parentName="p">{`theme.borderWidths`}</inlineCode>{` and `}<inlineCode parentName="p">{`borderBottomColor`}</inlineCode>{` comes from `}<inlineCode parentName="p">{`theme.colors`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<>
  <Heading sx={{paddingBottom: 2}}>Heading</Heading>

  <Heading
    sx={{
      paddingBottom: 2,
      borderBottomWidth: 1,
      borderBottomColor: 'border.default',
      borderBottomStyle: 'solid',
    }}
  >
    Heading with bottom border
  </Heading>
</>
`}</code></pre>
    <h2>{`Responsive values`}</h2>
    <p>{`Values in the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop can be provided as arrays to provide responsive styling.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Box
  sx={{
    backgroundColor: [
      'open.emphasis', // default
      'done.emphasis', // small       (min-width: 544px)
      'accent.emphasis', // medium    (min-width: 768px)
      'danger.emphasis', // large     (min-width: 1012px)
      'attention.emphasis', // xlarge (min-width: 1280px)
    ],
    padding: [2, 2, 4],
    color: 'fg.onEmphasis',
    borderRadius: 2,
  }}
>
  Resize window to see responsive background color
</Box>
`}</code></pre>
    <p>{`This generates the following CSS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.Box-hsdYAF {
  background-color: var(--bgColor-open-emphasis); /* default */
  padding: 8px;
  color: var(--fgColor-onEmphasis);
  border-radius: 6px;
}
@media screen and (min-width: 544px /* small */) {
  .Box-hsdYAF {
    background-color: var(--bgColor-done-emphasis);
    padding: 8px;
  }
}
@media screen and (min-width: 768px /* medium */) {
  .Box-hsdYAF {
    background-color: var(--bgColor-accent-emphasis);
    padding: 16px;
  }
}
@media screen and (min-width: 1012px /* large */) {
  .Box-hsdYAF {
    background-color: var(--bgColor-danger-emphasis);
  }
}
@media screen and (min-width: 1280px /* xlarge */) {
  .Box-hsdYAF {
    background-color: var(--bgColor-attention-emphasis);
  }
}
`}</code></pre>
    <h2>{`Nesting, pseudo-classes, and pseudo-elements`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop also allows for declaring styles based on media queries, pseudo-classes, and pseudo-elements. This example, though contrived, demonstrates the ability:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Box
  sx={{
    '> *': {
      borderWidth: 1,
      borderColor: 'border.default',
      borderStyle: 'solid',
      borderBottomWidth: 0,
      padding: 2,
      ':last-child': {
        borderBottomWidth: 1,
      },
      ':hover': {
        bg: 'neutral.muted',
      },
    },
  }}
>
  <Box>First</Box>
  <Box>Second</Box>
  <Box>Third</Box>
</Box>
`}</code></pre>
    <h2>{`Value reference`}</h2>
    <ul>
      <li parentName="ul">{`For a reference of possible values for this prop, see the `}<a parentName="li" {...{
          "href": "https://github.com/styled-system/styled-system/blob/master/docs/table.md"
        }}>{`Styled System reference table`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      